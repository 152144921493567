import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/descaling.sass"
import Image from "../../components/image"
import Carousel from 'react-bootstrap/Carousel'

const Landscaping = () => (
  <div className="descaling-component">
    
    <SEO title="Landscaping" />

    <div className="service-header">
      <h1>Landscaping</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>
      Discover the beauty of outdoor living with our exceptional landscaping services. From seasonal cleanings to creating serene outdoor spaces with expert plantings, 
      retaining walls, and elegant landscape lighting, we transform your yard into a picturesque retreat.
      Trust us to enhance the charm and functionality of your outdoor areas, making them perfect for relaxation and entertainment.
      </p>
    </div>

    <div className="desktop-image-video">

      <Carousel>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="landscaping.jpeg"/>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="landscapingtwo.png"/>            
            </div>
          </Carousel.Item>
      </Carousel>

    </div>
    
    <div className="descaling-statements desktop-statements">    
      <ul className="sewer-services-list">
        <li>Mulching</li>
        <li>Spring, fall, and summer cleanings</li>
        <li>Tree and plant planting</li>
        <li>Landscape lighting</li>
        <li>Retaining walls, patios, patio walkways</li>
        <li>Fencing</li>
        <li>Decking</li>
        <li>Pergolas</li>
        <li>Privacy walls</li>
        <li>Awnings</li>
        <li>Custom pieces</li>
        <li>And more</li>
      </ul>
    </div>

  </div>
)

export default Landscaping
